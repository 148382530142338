import { notificationApiURL } from "../config";

export const sendSubscriptionSuccessEmail = (emailAddress) => {
    const encodedValue = encodeURIComponent(emailAddress);
    fetch(`${notificationApiURL}/notification/subscription?contactAddress=${encodedValue}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic cmVuZXlvOnBhc3N3b3Jk"
        }
    })
}

export const saveContactForm = (contactFormData) => {
    fetch(`${notificationApiURL}/notification/contact`, {
        method: 'PUT',
        body: JSON.stringify(contactFormData),
        headers: {
            "Content-Type": "application/json",
        }
    });
}

import Logo from '../../assets/logo.svg';
import contactDetails from '../../assets/contact.json';
import Subscription from './subscription';
import Follow from './follow';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { disableContact, disableSubscribe } from '../../config';

const Footer = ({ subscribe }) => {
    return (
        <div className="footer d-flex flex-wrap justify-content-center align-items-center pt-5 mt-5 border-top flex-column gap-2">
            { (!disableSubscribe) ? <Subscription subscribe={subscribe} /> : null }
            <div className="row-6 row-md-4 d-flex align-items-center justify-content-center w-100">
                <div className='d-flex flex-row align-items-center justify-content-start w-75 me-auto'>
                    <img alt="logo" className="bi" width="60" height="60" src={Logo} />
                    <span className="mb-md-0 text-muted">© 2023 Reneyo Tech Inc</span>
                </div>
                { (disableContact) ?
                        <OverlayTrigger overlay={<Tooltip>{contactDetails.email}</Tooltip>}>
                            <a href={`mailto:${contactDetails.email}`}><i className="contact-icon fa-solid fa-envelope"></i></a>
                        </OverlayTrigger> : null
                }
                <Follow />
            </div>
        </div>
    );
}

export default Footer;

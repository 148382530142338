import followLinks from '../../assets/follow.json';
import { map } from 'lodash';

const Follow = () => {

    const FollowLink = ({ follow }) => (
        <a href={follow.link} target='_blank' rel='noreferrer' key={follow.icon}>
            <i className={`align-text-center follow-icon fa-brands fa-${follow.icon}`}></i>
        </a>
      );

    return (
        <div className='follow-links d-flex align-items-center me-4 p-2 gap-2'>
            {
                map(followLinks, (followLink) =>
                    <FollowLink follow = {followLink} />
                )
            }
        </div>
    )
}

export default Follow;

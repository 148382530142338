import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer'
const SuccessToast = (props) => {
    return (
        <ToastContainer position="bottom-start" containerPosition="fixed" className='mb-3 ms-1'>
            <Toast onClose={props.close} show={props.show} delay={2000} autohide>
                <Toast.Body>{props.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default SuccessToast;

import { disableContact } from "./config";

export const orgName = 'Reneyo Tech';

export const servicesIconsFolder = "/services-icons";

const getHeaderLinks = () => {
    let headerLinks = [
        {
            title: 'Home',
            to: '/'
        }, {
            title: 'Services',
            to: '/services'
        }, {
            title: 'About',
            to: '/about'
        }
    ];
    if(!disableContact) {
        headerLinks.push({
            title: 'Contact',
            to: '/contact'
        });
    }
    return headerLinks;
}

export const headerLinks = getHeaderLinks();

export const errorMessages = {
    'nameEmpty': "Please provide your name",
    'emailEmpty': "Please provide your email address",
    'emailInvalid': "Please provide a valid email address",
    'questionEmpty': "Please provide your question"
};

import Header from './common/header';
import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './home';
import Footer from './common/footer';
import Contact from './contact';
import SuccessToast from './common/SuccessToast';
import { saveContactForm, sendSubscriptionSuccessEmail } from '../js/fetch';
import About from './about';
import Services from './services';
import PrivacyPolicy from './privacy-policy';
import NotFound from './common/not-found';
import { disableContact } from '../config';

const App = () => {
  const [toast, setToast] = useState({ showToast: false, toastMessage: "" });

  const subscribe = (email) => {
    sendSubscriptionSuccessEmail(email);
    setToast({ showToast: true, toastMessage: "Thank you for subscribing!" })
  }

  const submitContact = (contact) => {
    saveContactForm(contact);
    setToast({ showToast: true, toastMessage: "We will respond to you within 24 hours." })
  }

  const closeToast = () => {
    setToast({ showToast: false, toastMessage: "" });
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/about" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        { (!disableContact) ? <Route exact path="/contact" element={<Contact submitContact={submitContact} />} /> : null }
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer subscribe={subscribe} />
      { <SuccessToast close={closeToast} show={toast.showToast} message={toast.toastMessage} /> }
    </div>
  );
}

export default App;

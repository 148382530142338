import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import about from '../../assets/about.json';
import Principles from './principles';
import parse from 'html-react-parser';

const About = () => {
    return (
        <div id='about-page'>
            <div className='vision p-5 my-5'>
                <h1 className='display-2'>Our Vision</h1>
                <p className='lead text-wrap text-break'>{about.vision}</p>
            </div>
            <Container>
                <Row xs={1} lg={2} className='gap-4 gap-lg-0'>
                    <Col>
                        <div className='about-border d-flex row m-2 mt-2 pb-2 border justify-content-center align-items-center'>
                            <h1 className='heading p-5'>Wo we are?</h1>
                            <p className='lead text-wrap text-break'>{parse(about.who_we_are)}</p>
                        </div>
                    </Col>
                    <Col>
                        <div className='about-border d-flex row m-2 mt-2 pb-2 border justify-content-center align-items-center'>
                            <h1 className='heading p-5'>What we do?</h1>
                            <p className='lead text-wrap text-break'>{parse(about.what_we_do)}</p>
                        </div>
                    </Col>
                </Row>
                <Row xs={1}>
                    <Col className='w-100'>
                        <div className='about-border d-flex row m-5 mx-2 border'>
                            <h1 className='heading w-100 p-5'>Our Core Values</h1>
                            <Principles principles={about.principles} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default About;

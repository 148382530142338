import services from '../../assets/services.json';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { map, isEmpty, isEqual } from 'lodash';
import { servicesIconsFolder } from '../../constants';
import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import parse from 'html-react-parser';


const Services = () => {

    const [service, setService] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [service])

    const handleClick = (index) => {
        const selectedService = services.servicesList[index];
        setService(selectedService);
    }

    const servicesHeading = () => {
        if(isEmpty(service)) {
            return (
                <h1 className='heading p-5'>Services</h1>
            );
        } else {
            return (
                <div className='heading'>
                    <Dropdown className='service-dropdown'>
                        <Dropdown.Toggle className='w-100 justify-content-center'>{service.heading}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                map(services.servicesList, (eachService, idx) => {
                                    return (<Dropdown.Item key={idx} as='button' className='dropdown-item me-5' active={isEqual(eachService.heading, service.heading)} onClick={() => handleClick(idx)}>{eachService.heading}</Dropdown.Item>)
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            );
        }
    }

    const servicesList = () => {
        return(
            <Row xs={1} md={2} lg={3} className='g-5 m-0 mb-2 mt-0 pt-0 pb-4'>
                {map(services.servicesList, (service, idx) =>
                    <Col key={idx}>
                        <Card className='service-card h-100' onClick={() => handleClick(idx)}>
                            <Card.Body className='d-flex row justify-content-center align-items-center'>
                                <img alt={`${service.icon}`} className='h-50 w-50' src={`${servicesIconsFolder}/${service.icon}.svg`} />
                                <Card.Title className='text-center align-text-bottom'>{service.heading}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        );
    }

    const subServicesList = () => {
        return (
            <Row xs={1} md={2} lg={3} className='g-5 m-0 mb-2 mt-0 pt-0 pb-4'>
                {map(service.sub_services, (subService, idx) =>
                    <Col key={idx}>
                        <Card className='sub-service-card h-100'>
                            <Card.Body className='d-flex row justify-content-center align-items-center'>
                                <Card.Title className='service-sub-heading text-center'>{subService.heading}</Card.Title>
                                <Card.Text><p className='text-center align-text-bottom lead'>{subService.description}</p></Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>
        );
    }

    const whyChooseUs = () => {
        return (
            <ListGroup variant='flush' className='border-0 p-4'>
                {
                    map(services.why_us, (statement) => {
                        return (
                            <ListGroup.Item className='border-0'><i class="check-icon bi bi-check2-circle"></i>{parse(statement)}</ListGroup.Item>
                        )
                    })
                }
            </ListGroup>
        )
    }

    return (
        <>
            <div className='services-border d-flex row m-5 border'>
                {servicesHeading()}
                {isEmpty(service) ? servicesList() : subServicesList()}
            </div>
            <div className='services-border d-flex row m-5 border'>
                <h1 className='heading p-5'>Why Us?</h1>
                {whyChooseUs()}
            </div>
        </>

    );
}

export default Services;

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { map } from 'lodash';

const Principles = ({principles}) => {
    return (
        <Row xs={1} md={2} lg={principles.length} className='g-4 m-0 mb-2 mt-0 pt-0 pb-4'>
            {map(principles, (principle, idx) =>
                <Col key={idx}>
                    <Card className='principle-card h-100'>
                        <Card.Body>
                            <Card.Title className='principle-heading text-center'>{principle.heading}</Card.Title>
                            <Card.Text><p className='text-center align-text-bottom lead'>{principle.content}</p></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Row>
    );
};

export default Principles;

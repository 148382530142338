import { Image } from 'react-bootstrap';
import LogoMain from '../../assets/logo-main.png';

const Home = () => {
    return (
        <div className='d-flex flex-column mb-5 pb-5 text-center gap-4 mobile-height'>
            <Image
                className='mx-auto'
                src={LogoMain}
                height="400"
                width="400"
                loading="lazy"
                alt="RENEYO TECH"
            />
            <p className='lead'>We are problem-solvers, great-thinkers, and those who can laugh at your not-so-funny jokes.</p>
        </div>
    );
}

export default Home;

import { Image } from 'react-bootstrap';
import image from '../../assets/not-found.jpg';

const NotFound = () => {
    return (
        <div className='not-found d-flex align-items-center justify-content-center'>
            <Image
                src={image}
                height="190"
                width="400"
                loading="lazy"
                alt="404"
            />
        </div>
    );
}

export default NotFound

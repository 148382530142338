import { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/logo.svg';
import { headerLinks, orgName } from '../../constants';

const useOutsideClick = (callbackFunc) => {
    const ref = useRef();

    useEffect(() => {
      const handleSelect = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            callbackFunc();
        }
      };

      document.addEventListener('click', handleSelect, true);

      return () => {
        document.removeEventListener('click', handleSelect, true);
      };
    }, [ref, callbackFunc]);

    return ref;
  };


const Header = () => {
    const [expanded, isExpanded] = useState(false);
    const navigate = useNavigate();
	const location = useLocation();

    const handleToggle = () => {
        isExpanded(!expanded);
    }

    const handleSelect = () => {
        isExpanded(false);
    }

    const handleClickOutside = useCallback(() => {
        isExpanded(false);
      }, []);

    const ref = useOutsideClick(handleClickOutside);

    return (
        <Navbar ref={ref} id='nav-header' onToggle={handleToggle} collapseOnSelect onSelect={handleSelect} expanded={expanded} bg="white" expand="lg" sticky="top" className='pt-0'>
            <Container className="py-2 border-bottom shadow container mx-0 px-0 mw-100">
                <Navbar.Brand className="ps-2" as="a"  onClick={() => navigate("/")}>
                    <img
                        src={Logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                    {orgName}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto mt-2 gap-2 pe-4" activeKey={location.pathname}>
                        { headerLinks.map((headerLink) => <Nav.Item key={headerLink.to}><Nav.Link href={headerLink.to} className="mx-1 px-2 text-center nav-link-button border rounded-3 border-2" eventKey={headerLink.to}>{headerLink.title}</Nav.Link></Nav.Item>)}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
